import React, { useEffect, useRef, useState } from "react";
import styles from "./Hero.module.css";
import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar.jsx";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import "./slider.css";
import { Pagination, Autoplay } from "swiper/modules";
import ResponsiveVideoPlayer from "../VideoPlayer/VideoPlayer.js";
import  {statsDataHero} from '../data.js'


export default function Hero() {
 

  const [stats, setStats] = useState(
    statsDataHero.map((stat) => ({ ...stat, currentValue: 0 }))
  );

  const animateValue = (start, end, duration, index) => {
    let startTime = null;
    const step = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = Math.min((timestamp - startTime) / duration, 1);
      setStats((prevStats) => {
        const newStats = [...prevStats];
        newStats[index] = {
          ...newStats[index],
          currentValue: Math.floor(progress * (end - start) + start),
        };
        return newStats;
      });
      if (progress < 1) {
        requestAnimationFrame(step);
      }
    };
    requestAnimationFrame(step);
  };

  useEffect(() => {
    stats.forEach((stat, index) => {
      animateValue(0, stat.value, 2000, index);
    });
  }, []);
  return (
    <>
      <Navbar />
      <div className={styles.heroSection}>
        <div className={styles.leftSide}>
          <div className={styles.headingWrapper}>
            <h1 className={styles.mainHeading}>
              AN INTELLIGENT
              <br />
              Unified{" "}
              <span className={styles.logo}>
                <img src="/images/greenLogo.svg" alt="logo" />
              </span>{" "}
              Oracle<span className={styles.greenUnder}>_</span>
            </h1>
            <p className={styles.subHeading}>
              A New Ground For Market Data Predictions.
              <br />
              Anytime, Anywhere.
            </p>
          </div>

          <Link to={"https://dashboard.yoracle.ai"} className={styles.button}>
            Access Oracle
          </Link>

          <div className={styles.dashboardWrapperM}>
            <ResponsiveVideoPlayer videoSrc="https://dl.dropbox.com/scl/fi/q7eed30x43eitgghjfgxq/landing-page-video.mp4?rlkey=e1qcta46qdjtvz2gbud8w0gjf&st=bto1wd5z&dl=0" />
          </div>

          <div className={styles.statsContainerBorder}>
            <div className={styles.statsContainer}>
              {stats.map((stat, index) => (
                <React.Fragment key={stat.label}>
                  {index > 0 && <div className={styles.spacer}></div>}
                  <div className={styles.stats}>
                    <h1>
                      {stat.prefix}
                      {stat.currentValue}
                      {stat.unit}
                    </h1>
                    <p>{stat.label}</p>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
          <Link to={"https://dashboard.yoracle.ai"} className={styles.buttonM}>
            Access Oracle
          </Link>
          <div className={styles.sliderCont}>
            <div className={styles.slider}>
              <Swiper
                slidesPerView={1}
                // spaceBetween={0}
                autoplay={{
                  delay: 3500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination, Autoplay]}
              >
                {stats.map((stat) => (
                  <SwiperSlide key={stat.label}>
                    <div className={styles.sliderBorder}>
                      <div className={styles.smallSlider}>
                        <h1>
                          {stat.prefix}
                          {stat.currentValue}
                          {stat.unit}
                        </h1>
                        <p>{stat.label}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>

        <div className={styles.rightSide}>
          <div className={styles.dashboardWrapper}>
            <ResponsiveVideoPlayer videoSrc="https://dl.dropbox.com/scl/fi/q7eed30x43eitgghjfgxq/landing-page-video.mp4?rlkey=e1qcta46qdjtvz2gbud8w0gjf&st=bto1wd5z&dl=0" />
          </div>
        </div>
      </div>
    </>
  );
}
